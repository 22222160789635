//import {useState, useEffect} from 'react';
//import Props from "./Props";

const Events = () => {
  return (
    <div>
      <div className="about-container">
        <div className="about-top" />

        <div className="about-content">
          <h3 className="header">Events</h3>
          <ul className="board-members">
            <li><b>October: </b> Annual Community Garage Sale</li>
             
            <li><b>January: </b> Community New Years Eve Party</li>
            
            <li><b>February: </b>Annual HOA Meeting</li>
             
            <li><b>March/April: </b>Meet in the Street</li>
          </ul>

          <br />
          <h3 className="header">Board Meetings</h3>
          <p className="justify">
            Casa Sunrise HOA meetings are held on a monthly basis with the exception of July. The location of these
            meetings is based on a rotating system with members volunteering to host the meeting at their home. The
            location and time of the monthly meeting will be published in the minutes of the previous month.
          </p>
          <br />
          <p className='justify'>The Annual Meeting will be held in February of each year.</p>
        </div>
      </div>
    </div>
  );
};

export default Events;
