import "./styles.css";
import bimg from "./images/desert2.jpg";
import Navbar from "./Navbar";
import Home from "./Home";
import AboutUs from "./AboutUs"
import Events from "./Events"
import Resources from "./Resources"
import Contact from "./Contact"
import Footer from "./Footer";
import News from "./News";
import {useState, useEffect} from "react";

export default function App(
  //Pass Props:

) {
  //0 == home //1 == board //2 == events //3 == resources //4 == contact
  const [nav, setNav] = useState(0);

  return (
    <div>
      <div className="background" style={{backgroundImage: `url(${bimg})`, }}> 
        <Navbar setNav={setNav} nav={nav} />
        {(() => {
        if (nav == 0) {
          return (
            <Home setNav={setNav} />
          )
        } else if (nav == 1) {
          return (
            <AboutUs />
          )
        } else if (nav == 2) {
          return (
            <Events />
          )
        } else if (nav == 3) {
          return (
            <Resources />
          )
        } else if (nav == 4) {
          return (
            <Contact />
          )
        } 
      })()}
      </div>
    {/*<News /> */}
    <Footer />
    </div>
  );
}
