//import {useState, useEffect} from 'react';
//import Props from "./Props";

const Contact = () => {
    return (
    <div>
        <div className="about-container"> 
            <div className="about-top" />
            
            <div className="about-content">
                <h3>Contact Us!</h3>
                
                <br />
                <p><b>For questions or concerns regarding Casa Sunrise Association:</b></p>
                <p>sanemeth@comcast.net</p>
                
            </div>
        </div>   
    </div>
 );
}

export default Contact;