//import {useState, useEffect} from 'react';
//import Props from "./Props";
import CCR from './images/CCR.pdf'

const Resources = () => {
  return (
    <div>
      <div className="about-container">
        <div className="about-top" />

        <div className="about-content">
          {/*
                
                <h3 className="header">Rules and Regulations</h3>
                <ul className="board-members">
                    <li>Resource 1</li>
                    <li>Resource 2</li>
                </ul>

                <br />
                <h3 className="header">Minutes</h3>
                <ul className="board-members">
                    <li>Resource 1</li>
                    <li>Resource 2</li>
                    <li>Resource 3</li>
                </ul>

                <br />
                <h3 className="header">By-Laws</h3>
                <ul className="board-members">
                    <li>Resource 1</li>
                    <li>Resource 2</li>
                </ul>
                <br />
                
                */}

          <h3 className="header">Resources</h3>
          <ul className="board-members">
              <li>
                <a className='home-link' href={CCR} target='_blank'>Covenants, Conditions, and Restrictions</a>
              </li>
              
          </ul>
          <br />

          <h3 className="header">Frequently Asked Questions</h3>
          <ul className="board-members">
            <li>
              <b>How much are the monthly dues?</b>
            </li>
            <p>$200.</p>

            <br />
            <li>
              <b>How are monthly dues paid?</b>
            </li>
            <p>Payment should be made to the treasurer.</p>

            <br />
            <li>
              <b>Who may attend monthly HOA meetings?</b>
            </li>
            <p>Anyone who resides within the community.</p>

            <br />
            <li>
              <b>How are HOA board members selected?</b>
            </li>
            <p>
              As terms expire, the owners vote for a candidate who is nominated
              for a vacant position. In the event that a Board Member resigns
              before his/her term is up, the President shall appoint a volunteer
              to complete the vacancy. Please refer to the Casa Sunrise HOA
              By-Laws for more detailed information.
            </p>

            <br />
            <li>
              <b>Who do we contact with concerns?</b>
            </li>
            <p>
              All concerns should be sent to the HOA email: sanemeth@comcast.net. A designee of the
              HOA board will review any concerns and respond to the resident's
              concern within 3 days or sooner if the concern refers to a safety
              issue.
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resources;
