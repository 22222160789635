//import {useState, useEffect} from 'react';
//import Props from "./Props";
import img3 from "./images/house3.jpg";

const AboutUs = () => {
  return (
    <div>
      <div className="about-container">
        <div className="about-top" />
        <div className="about-content">
          <h3 className="header">About Us</h3>
          <div
            className="img-example"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
          <p className="justify">
            Casa Sunrise is a small, friendly and community minded Townhome
            neighborhood located in the Catalina foothills in Tucson, Arizona.
            It was founded in 1978 and over the years Casa Sunrise has grown to
            contain 37 units. Casa Sunrise features a very active community that
            works together to assure the continuance of a safe and well
            maintained neighborhood.
          </p>

          <br />
          <h3 className="header">Board Members</h3>
          <ul className="board-members">
            <li>
              <b>President:</b> Scott Nemeth
            </li>
            <li>
              <b>Vice President:</b> David Krassowski
            </li>
            <li>
              <b>Treasurer:</b> Nancy Atherton
            </li>
            <li>
              <b>Secretary:</b> Mary Burnham-Moore
            </li>
            <li>
              <b>Director (Landscape):</b> Joan Robinson
            </li>
          </ul>

          <br />
          <h3 className="header">Committees</h3>
          <ul className="board-members">
            <li>Welcoming Committee</li>
            <li>Pool Committee</li>
            <li>Architecture Committee</li>
            <li>Landscaping Committee</li>
            <li>Social Committee</li>
          </ul>

        </div>
      </div>
    </div>
  );
};

export default AboutUs;
