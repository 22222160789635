//import {useState, useEffect} from 'react';
//import Props from "./Props";
import img1 from "./images/house1.jpg";
import img2 from "./images/house2.jpg";
import img3 from "./images/house3.jpg";
import imgPool from "./images/pool.jpg";

const Home = ({setNav}) => {

  function setNavigation(pageNum){
    setNav(pageNum);
    window.scrollTo(0,0);
  }

  return (
    <div>
      <div className="about-container">
        <div className="about-top" />
        <div className="about-content">
          <h3 className="header">Casa Sunrise Homeowners Association</h3>

          <p className="justify"> 
          Welcome to Casa Sunrise, a small and friendly community situated in the foothills of Tucson, Arizona. 
          It was founded in 1978 and over the years Casa Sunrise has grown to contain 37 units. 
          Casa Sunrise features a very active community that works together to assure the continuance of a safe and well maintained neighborhood.
          </p>
        
          <br />
          <div
            className="img-example"
            style={{ backgroundImage: `url(${img1})` }}
          ></div>
          <br />
          <div
            className="img-example"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <br />
          <div
            className="img-example"
            style={{ backgroundImage: `url(${imgPool})` }}
          ></div>
          
        </div>
        <div className='about-content'> 
          <div className='justify'>For additional details related to Casa Sunrise and its board members: 
          <scan className='home-link' onClick={() => setNavigation(1)}> About Us</scan>
          </div>
        </div>

        <div className='about-content'> 
          <div className='justify'>For information regarding events and board meetings: 
          <scan className='home-link' onClick={() => setNavigation(2)}> Events</scan>
          </div>
        </div>

        <div className='about-content'> 
          <div className='justify'>For resources and frequently asked questions: 
          <scan className='home-link' onClick={() => setNavigation(3)}> Resources</scan>
          </div>
        </div>

        <div className='about-content'> 
          <div className='justify'>For questions or concerns regarding the HOA 
              please contact: sanemeth@comcast.net
          </div>
        </div>

      </div>
    </div>
  );
};

export default Home;
