import AboutUs from "./AboutUs"

const Navbar = ({setNav, nav}) => {
  //setNav function for onclick
  //onClick={() => setNav(0)}

  function setNavigation(pageNum){
    setNav(pageNum);
    window.scrollTo(0,0);
  }

  return (
    <nav className="navbar">
      <div className="logo">
        <p onClick={() => setNavigation(0)}>Casa Sunrise</p>
      </div>
      <div className="center">
        <div className="links">
          <p className={nav === 0 ? 'active-nav' : 'inactive-nav'} onClick={() => setNavigation(0)}>Home</p>
        </div>
        <div className="links">
          <p className={nav === 1 ? 'active-nav' : 'inactive-nav'} onClick={() => setNavigation(1)}>About</p>
        </div>
        <div className="links">
          <p className={nav === 2 ? 'active-nav' : 'inactive-nav'} onClick={() => setNavigation(2)}>Events</p>
        </div>
        <div className="links">
          <p className={nav === 3 ? 'active-nav' : 'inactive-nav'} onClick={() => setNavigation(3)}>Resources</p>
        </div>
        <div className="links">
          <p className={nav === 4 ? 'active-nav' : 'inactive-nav'} onClick={() => setNavigation(4)}>Contact</p>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
