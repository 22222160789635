//import {useState, useEffect} from 'react';
//import Props from "./Props";

const Footer = () => {
    return (
    <div className="footer">
        
    </div>


    );
}

export default Footer;